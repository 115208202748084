import React, { useState, useEffect, useContext } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";

const Robottxt = () => {
  const url = sessionStorage.getItem("selectedURLLabel");

  const { setTransactionLoaderState,siteurl, siteid} = useContext(GlobalContext);
  const [robotdata, setrobotdata] = useState("");

  const FetchRobotsTxt = async () => {
    setTransactionLoaderState(true);
    const trimmedUrl = siteurl.trim();

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url: trimmedUrl }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/FetchRobotsTxt`,
        requestOptions
      );
      const data = await res.text();
      setrobotdata(data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    FetchRobotsTxt();
  }, [siteurl]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestBody = {
        action: "generate_robots",
        base_url: siteurl.trim(),
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };

      console.log("Sending data to the server:", requestBody);
      const res = await fetch(
        `${siteurl}/seo_manager.php`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === 200) {
        swal("Success", data.message, "success");
      } else {
        swal("Error", data.message, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
      swal("Error", "Failed to submit data. Please try again later.", "error");
    }
  };

  return (
    <>
      <div className="m-4">
        <h5 className="text-center mb-3">
          Robots.txt for <u>{siteurl}</u>
        </h5>
        <div
          id="robot-content"
          style={{
            border: "1px solid #ccc",
            padding: "15px",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
          }}
          dangerouslySetInnerHTML={{ __html: robotdata }}
        />
        <div className="mt-4">
          <Button type="submit" variant="primary" onClick={handleSubmit}>
            Generate Robots.txt
          </Button>
         
        </div>
      </div>
    </>
  );
};

export default Robottxt;
